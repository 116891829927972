<template>
  <div class="trade_wrapper">
    <div>
      <div class="base_box node_box">
        <div class="switch_box both_side">
          <div>
            <div class="align_center delay">
              <img src="../assets/img/wifi.png" alt="" srcset="" />{{
                nodeTime
              }}
              ms
            </div>
            <div class="link">{{ rpcUrl }}</div>
          </div>
          <div class="switch_btn" @click="clickNode">切換節點</div>
        </div>
        <div class="base_title">選擇公鏈</div>
        <div class="base_select mt20" :class="{ select_active: show1 }">
          <div class="content">
            <img
              :src="chainObj[selectChain].logo"
              class="select_img"
              srcset=""
            />{{ chainObj[selectChain].chainName }}
          </div>
          <div class="right_down" @click.stop="show1 = !show1,show2 = false"></div>
          <div class="downlist" v-click-outside="handleScroll">
            <div
              class="list_item"
              v-for="(item, index) in chains"
              @click="switchChain(item.chain)"
              :key="index"
            >
              <img :src="item.logo" class="select_img" srcset="" />{{
                item.chainName
              }}
            </div>
          </div>
        </div>
        <div class="base_title">選擇交易所</div>
        <div class="base_select mt20" :class="{ select_active: show2 }">
          <div class="content">
            <img
              :src="exchange[selectEx].logo"
              class="select_img"
              srcset=""
            />{{ exchange[selectEx].name }}
          </div>
          <div class="right_down" @click.stop="show2 = !show2,show1 = false"></div>
          <div class="downlist" v-click-outside="handleScroll">
            <div
              class="list_item"
              v-for="(item, index) in exchange"
              @click="switchExchange(index)"
              :key="index"
            >
              <img :src="item.logo" class="select_img" srcset="" />{{
                item.name
              }}
            </div>
          </div>
        </div>
      </div>
      <div v-show="uploadResult.length == 0">
        <div class="private_title">
          <span class="title">上傳私鑰</span>
        </div>
        <uploadExcel
          :tips="true"
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
        ></uploadExcel>
      </div>
      <div v-show="uploadResult.length">
        <div class="private_title">
          <div class="align_center">
            <span class="title">地址信息</span>
            <div class="refresh_box" @click="refreshBalance">
              <img src="../assets/img/a4.png" class="refresh_icon" :class="{refresh_animate:refresh}"/>
              <span class="total_num">刷新</span>
            </div>
          </div>
          <img src="../assets/img/close2.png" class="close" @click="removeAdd" />
        </div>
        <div class="base_box address_list">
          <div class="table_item table_header">
              <div class="status"></div>
              <div class="no"></div>
              <div class="address">操作地址</div>
              <div class="amout">{{Tokens[0].Symbol}}餘額Gas</div>
              <div class="amout">{{selectToken.Symbol}}餘額</div>
              <div class="amout">{{tokenOutInfo.symbol}}餘額</div>
              <div class="delete"></div>
          </div>
          <div class="table_content">
            <my-scroll>
            <div class="table_item" v-for="(item,index) in wallets" :key="index">
              <img src="../assets/img/a1.png" class="status" srcset="" v-show="!item.loading && item.status">
              <img src="../assets/img/a2.png" class="status" srcset="" v-show="!item.loading && !item.status">
              <div class="status" v-show="item.loading"></div>
              <div class="no">{{index+1}}</div>
              <div class="address">{{showAccount4(item.address)}}</div>
              <div class="amout">{{item.showBalance}}</div>
              <div class="amout">{{selectToken.address == chainConfig.WETH?item.showBalance:item.showUsdtBalance}}</div>
              <div class="amout">{{item.showTokenBalance}}</div>
              <img src="../assets/img/a3.png" @click="deleteAdd(index)" class="delete" srcset="">
            </div>
          </my-scroll>
          </div>
          <div class="table_item table_footer">
              <div class="status"></div>
              <div class="address" style="color:#000">縂操作 {{wallets.length}}</div>
              <div class="address" style="color:#000">執行成功 {{successNum}}</div>
              <div class="amout">{{totalBalance}} {{Tokens[0].Symbol}}</div>
              <div class="amout">{{selectToken.address == chainConfig.WETH?totalBalance:totalUsdtBalance}} {{selectToken.Symbol}}</div>
              <div class="amout">{{totalTokenBalance}} {{tokenOutInfo.symbol}}</div>
              <div class="delete"></div>
          </div>
          <div class="error_box" v-show="errors.length">
            <div class="error_title">
              <span class="text">以下地址無效或金额無效無法發送</span>
              <div class="delete_box" @click="deleteAll">
                <img src="../assets/img/a3.png" alt="" srcset="">一鍵刪除
              </div>
            </div>
            <div>
              <span v-for="(item,index) in errors" :key="index">第{{item.index}}行<span v-show="index != errors.length-1">、</span></span>无效
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base_box main_box">
      <div class="tabs_box">
        <div class="tabs_item " @click="switchBuy('buy')" :class="{tabs_act:buyType=='buy'}">{{selectToken.Symbol}} 買入</div>
        <div class="tabs_item" @click="switchBuy('sell')" :class="{tabs_act:buyType=='sell'}">卖出 {{tokenOutInfo.symbol}}</div>
      </div>
      <div class="col_2 mt20">
        <div class="col_2_item">
          <div class="base_title">交易幣種</div>
          <div
            class="base_select2"
            :class="{ select_active2: show3 }"
          >
            <img :src="selectToken.logo" class="select_img" srcset="" />
            <div>
              <div class="content">
                {{ selectToken.Symbol }}
                <div class="right_down" @click.stop="show3 = !show3"></div>
              </div>
              <div class="name">{{ selectToken.name }}</div>
            </div>
            <div class="downlist" v-click-outside="handleScroll">
              <div class="list_item"
               v-for="(item, index) in swapTokens"
              @click="selToken(index)"
              :key="index">
                <img
                  :src="item.logo"
                  class="select_img"
                  srcset=""
                />
                <div>
                  <div class="content"> {{ item.Symbol }}</div>
                  <div class="name">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col_2_item">
          <div class="base_title">流動池</div>
          <div
            class="base_select2"
            :class="{ select_active2: show4 }"
            
          >
            <img :src="selPoolToken.logo" class="select_img" srcset="" />
            <div>
              <div class="content">
                {{ selPoolToken.Symbol }}
                <div class="right_down" @click.stop="show4 = !show4"></div>
              </div>
              <div class="name">{{ selPoolToken.name }}</div>
            </div>
            <div class="downlist" v-click-outside="handleScroll">
              <div class="list_item"
                  v-for="(item, index) in Tokens"
                    @click="selectPoolToken(index)"
                    :key="item.Symbol">
                <img
                  :src="item.logo"
                  class="select_img"
                  srcset=""
                />
                <div>
                  <div class="content">{{ item.Symbol }}</div>
                  <div class="name">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="base_flex">
        <div class="title_box">
          <div class="both_side">
            <div class="base_title">合約地址</div>
            <div class="trade_price">當前 {{tokenOutInfo.symbol}} 價格：$ {{tokenOutInfo.showTokenPrice}}</div>
          </div>
        </div>
        <div class="trade_price num_box" :style="{visibility: visibility? 'visible':'hidden'}">
          授权成功：{{approveNum}}
        </div>
      </div>
      <div class="base_flex mt20">
        <div class="base_input">
          <input type="text" v-model="tokenOut" @blur="confirmTokenOut" placeholder="請輸入合约地址" />
        </div>
        <div class="base_btn approve_btn" @click="clickApprove('btn')">提前授權</div>
      </div>
      <div class="both_side">
        <div class="base_title align_center">{{buyType=='buy' ? '買入金額':'卖出數量'}}隨機範圍
         <Tooltip v-show="!status" max-width="300" :content="buyType=='buy' ? '若該地址餘額小於最小金額將會全部買入如果購買使用的是主網代幣購買則不會買入（如BSC鏈的BNB，ETH鏈的ETH，確保有手續費）':'若該地址餘額小於最小金額將會全部賣出'" placement="top">
          <img src="../assets/img/question.png" class="quest" srcset />
        </Tooltip>
        </div>
        <div class="fix_switch">
          {{buyType=='buy' ? '固定金額':'固定數量'}}<i-switch size="large" v-model="status" @on-change="change" />
        </div>
      </div>
      <div class="col_2 mt20" v-show="buyType=='buy'&&!status">
        <div class="col_2_item">
          <div class="base_input">
          <input type="text" v-model="amountIn" :placeholder="'最小金額('+(selectToken.Symbol)+')'" />
        </div>
        </div>
        <div>&nbsp;&nbsp;~&nbsp;&nbsp;</div>
        <div class="col_2_item">
          <div class="base_input">
          <input type="text" v-model="amountInMax" :placeholder="'最大金額('+(selectToken.Symbol)+')'"/>
        </div>
        </div>
      </div>
      <div class="col_2 mt20" v-show="buyType!='buy'&&!status">
        <div class="col_2_item">
          <div class="base_input">
          <input type="text" v-model="sellAmountIn" :placeholder="'最小数量('+(tokenOutInfo.symbol)+')'" />
        </div>
        </div>
        <div>&nbsp;&nbsp;~&nbsp;&nbsp;</div>
        <div class="col_2_item" >
          <div class="base_input">
          <input type="text" v-model="sellAmountInMax" :placeholder="'最大数量('+(tokenOutInfo.symbol)+')'" />
        </div>
        </div>
      </div>
      <div class="base_input mt20" v-show="status">
        <input type="text" v-model="amount" :placeholder="buyType=='buy' ? '輸入固定金額（'+selectToken.Symbol+'）若該地址餘額不足將自動跳過':'輸入固定数量（'+tokenOutInfo.symbol+'）若該地址餘額不足將自動跳過'" />
      </div>
      
      <div class="base_title" v-show="!instatus">時間間隔</div>
      <div class="base_input mt20" v-show="!instatus">
        <input type="text" v-model="timeMargin" placeholder="請輸入每個地址交易時間間隔,1000=1秒，默認1000" />
      </div>
     <div class="base_title mt20">立即成交<i-switch style="margin-left:10px" size="large" v-model="instatus" /></div>
      <div class="base_title">交易滑點最大百分比</div>
      <div class="base_input">
        <input
          type="text"
          v-model="slige"
          placeholder="默認20%，建議必填，避免滑點過大造成損失"
        />
      </div>
      <div class="base_btn2" @click="clickpersonal" v-if="personal">簽名驗證</div>
      <div class="base_btn2" @click="clickSend" v-else>
        {{buyType=='buy' ? '執行批量買入':'執行批量卖出'}}
      </div>
      <div class="base_btn2" v-show="!instatus && index > 0" @click="clearCheckBuyInterval">
        取消執行
      </div>
    </div>
    <Modal
      v-model="amountModal"
      class-name="node_modal"
      footer-hide
      :closable="false"
      width="600"
    >
        <div class="modal_header">
          <div class="modal_title">
            <img src="../assets/img/node_icon.png" alt="" srcset="">切換節點
          </div>
          <img
            src="../assets/img/vipclose.png"
            class="close_icon"
            srcset
            @click="amountModal = false"
          />
        </div>
        <div class="align_center">
          <div class="base_input" style="flex: 1">
            <input
              type="text"
              v-model="tempUrl"
              :placeholder="$t('tool.14')"
            />
          </div>
          <span
            class="base_btn"
            style="width: 100px; margin-left: 20px"
            @click="clickConfirm"
            >確認</span
          >
        </div>
    </Modal>
  </div>
</template>

<script>
import BN from "bn.js";
import { chainObj2 as chainObj } from "../config";
import uploadExcel from "@/components/uploadExcelPrivateKey";
import {
  ERC20_ABI,
  Common_ABI,
  SwapRouter_ABI,
  MAX_INT
} from "../config";
import {
  showFromWei,
  showFromWeiMore,
  showAccount3,
  showAccount4,
  toWei,
  clickOutside
} from "../utils";
function countTotal(arr, keyName) {
	let $total = 0;
	$total = arr.reduce(function (total, currentValue, currentIndex, arr){
	    return currentValue[keyName] ? (total + parseFloat(currentValue[keyName])) : total;
	}, 0);
	return $total;
}
export default {
  directives: {clickOutside},
  components: { uploadExcel },
  props: {
    address: {
      type: String,
    }
  },
  data() {
    return {
      
      
      chainObj,
      selectEx: 0,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      selectChain: "BSC",
      rpcUrl: chainObj["BSC"].RPC,
      nodeTime: 212,
      uploadResult: [],

      //兑换的币种数组，一般是主币和USDT
      swapTokens: chainObj["BSC"].Tokens.slice(0, 2),
      //当前选择的兑换代币，一般是主币或者USDT
      selectToken: chainObj["BSC"].Tokens[0],
      selPoolToken: chainObj["BSC"].Tokens.slice(0, 2)[0],
      //价值币数组，用于查价格或者代币滑点
      Tokens: chainObj["BSC"].Tokens,
      //输入框代币合约地址
      tokenOut: null,
//输入框代币合约对应的代币信息
      tokenOutInfo: {
        showTokenPrice: ''
      },

      gasMulti: "",
      amount: "",
      inputData: "",
      toAddress: "",
      tempUrl: "",
      amountModal: false,
      //路由
      swapRouter: chainObj["BSC"].Dexs[0].SwapRouter,
      //当前选择的链配置
      chainConfig: chainObj["BSC"],
      USDTDetail: chainObj["BSC"].Tokens[1],
      buyType: "buy",
      slige:"",
      timeMargin:"",
      status: false,
      instatus: false,

      //买入最小金额
        amountIn: null,
        //买入最大金额
        amountInMax: null,

      sellAmountIn: null,
        sellAmountInMax: null,
        index: 0,
        visibility: false,
        approveNum: 0,
      personal: true,
      wallets: [],
      refresh: false,
      errors: []
    };
  },
  computed: {
    chains() {
      return Object.values(this.chainObj);
    },
    exchange() {
      return this.chainObj[this.selectChain].Dexs;
    },
    successNum() {
      return this.wallets.filter(item => item.status).length
    },
    totalBalance() {
      let number = countTotal(this.wallets, 'showBalance')
      return String(number).replace(/^(.*\..{6}).*$/,"$1");
    },
    totalTokenBalance() {
      let number = countTotal(this.wallets, 'showTokenBalance')
      return String(number).replace(/^(.*\..{6}).*$/,"$1");
    },
    totalUsdtBalance() {
      let number = countTotal(this.wallets, 'showUsdtBalance')
      return String(number).replace(/^(.*\..{6}).*$/,"$1");
    },
    // columns() {
    //   let list = [
    //     {
    //         title: '地址',
    //         slot: 'address'
    //     },
    //     {
    //         title: this.selectToken.Symbol,
    //         key: 'showBalance'
    //     },
    //     {
    //         title: 'USDT',
    //         key: 'showUsdtBalance'
    //     }
    //   ]
    //   if (this.tokenOutInfo && this.tokenOutInfo.address) {
    //     list.push({
    //       title: this.tokenOutInfo.symbol,
    //       key: 'showTokenBalance'
    //     })
    //   }
    //   return list
    // }
  },
  // mounted() {
  //   this.getTime();
  //   window.addEventListener("scroll", this.handleScroll, true);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.handleScroll, true);
  // },
  methods: {
    change() {
      this.amount = ''
    },
    handleScroll() {
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
    },
    switchBuy(type) {
      if (this.buyType == type) return;
      this.buyType = type;
      this.clearCheckBuyInterval();
    },
    clickNode() {
      this.amountModal = true
    },
    async clickConfirm() {
      try {
        var Web3 = require("web3");
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.tempUrl));

      let time = await myWeb3.eth.getGasPrice();
      this.rpcUrl = this.tempUrl
      this.amountModal = false
      this.getTime()
      } catch (error) {
        console.log(error)
        this.$message.error(error.message)
      }
      
    },
    showAccount3,
    showAccount4,
    deleteAll() {
      let wallets = this.wallets
      this.errors.forEach(item => {
        let index = wallets.findIndex(wallet => wallet.address == item.address)
        wallets.splice(index, 1)
      })
      this.uploadResult = wallets
      this.wallets = wallets
      this.errors = []
    },
    removeAdd() {
      this.uploadResult = []
      this.wallets = []
      this.errors = []
    },
    deleteAdd(index) {
      this.wallets.splice(index, 1);
    },
    async getTime() {
      var start = new Date().getTime();

      var Web3 = require("web3");
      const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));

      let time = await myWeb3.eth.getGasPrice();
      var end = new Date().getTime();
      console.log("cost is", `${end - start}ms`);
      this.nodeTime = end - start;
    },
    switchChain(chain) {
      console.log("chain", chain)
      this.selectChain = chain;
      this.chainConfig = chainObj[chain];

      this.selectToken = chainObj[chain].Tokens[0];
      this.Tokens = chainObj[chain].Tokens;
      this.swapRouter = chainObj[chain].Dexs[0].SwapRouter;
      this.USDTDetail = chainObj[chain].Tokens[1]
      this.rpcUrl = chainObj[chain].RPC;
      // this.gasMulti = chainObj[chain].gasMulti
      this.swapTokens = chainObj[chain].Tokens.slice(0, 2)

      this.selPoolToken = chainObj[chain].Tokens[0]

      this.getTime();
      this.show1 = false;
    },
    // 选择交易所
    switchExchange(index) {
      console.log(this.exchange)
      this.selectEx = index;
      this.swapRouter = this.exchange[index].SwapRouter;
      this.show2 = false;
    },
    //选择交易币种
    selToken(index) {
      this.selectToken = this.swapTokens[index];
      this.show3 = false;
    },
    //选择指定池子
    selectPoolToken(index) {
      this.selPoolToken = this.Tokens[index];
      this.show4 = false;
    },
    beforeUpload(file) {
      if(!this.tokenOut) {
        this.$message.error("請輸入合约地址");
        return false
      }
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }
      this.$message.error("Please do not upload files larger than 1m in size.");
      return false;
    },
    handleSuccess({ header, results }) {
      if (results.length > 0) {
        // 拿到秘钥
        this.uploadResult = results;
        console.log(results);
        var Web3 = require("web3");
        const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
        console.log(myWeb3.eth.accounts);
        try {
          this.uploadResult.forEach(async (item) => {
            if(!item.address) {
              let account = myWeb3.eth.accounts.privateKeyToAccount(
                item.privateKey
              );
              console.log(account);
              item.address = account?.address;
            }
            item.status = false
            item.loading = true
          });
          console.log(this.uploadResult);
          this.wallets = this.uploadResult
          this.batchGetTokenBalance()
        } catch (error) {
          console.log(error);
          this.$message.error("privateKey Error");
        }
      }
    },
    async refreshBalance() {
      this.refresh = true
      setTimeout(() => {
        this.refresh = false
      }, 1000)
      setTimeout(() => {
          let wallets = this.wallets;
          let length = wallets.length;
          this.errors = []
          for (let index = 0; index < length; index++) {
            wallets[index].loading = true
            wallets[index].status = false
              this.getTokenBalance(wallets[index], index, 'refresh');
          }
      }, 30);
    },
    async batchGetTokenBalance() {
        setTimeout(() => {
            let wallets = this.uploadResult;
            let length = wallets.length;
            for (let index = 0; index < length; index++) {
              wallets[index].loading = true
                this.getTokenBalance(wallets[index], index);
            }
        }, 30);
    },
    async getTokenBalance(wallet, index, refresh) {
        try {
            var Web3 = require("web3");
            const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
            //主币余额
            let balance = await myWeb3.eth.getBalance(wallet.address);
            let showBalance = showFromWei(balance, 18, 4);
            wallet.showBalance = showBalance;
            wallet.balance = new BN(balance, 10);
            //USDT 余额
            const usdtContract = new myWeb3.eth.Contract(ERC20_ABI, this.USDTDetail.address);
            let usdtBalance = await usdtContract.methods.balanceOf(wallet.address).call();
            let showUsdtBalance = showFromWei(usdtBalance, this.USDTDetail.decimals, 4);
            wallet.showUsdtBalance = showUsdtBalance;
            wallet.usdtBalance = new BN(usdtBalance, 10);
            //代币余额
            if (this.tokenOutInfo && this.tokenOutInfo.address) {
                const tokenContract = new myWeb3.eth.Contract(ERC20_ABI, this.tokenOutInfo.address);
                let tokenBalance = await tokenContract.methods.balanceOf(wallet.address).call();
                let showTokenBalance = showFromWei(tokenBalance, this.tokenOutInfo.decimals, 4);
                wallet.showTokenBalance = showTokenBalance;
                wallet.tokenBalance = new BN(tokenBalance, 10);
            }
            
            this.wallets[index] = wallet
            this.$set(this.wallets,index,wallet)
            console.log("wallet", wallet)
        } catch (e) {
            console.log("e", e);
        } finally {
          if(!wallet.address ||  wallet.showBalance == 0) {
              this.errors.push({
                index: index+1,
                address: wallet.address
              })
          }
          if(refresh == 'refresh' && index == (this.wallets.length - 1)) {
            this.$message('刷新成功');
          }
        }
    },
    clearCheckBuyInterval() {
      this.index = 0
        if (this._refreshCheckBuyIntervel) {
            clearInterval(this._refreshCheckBuyIntervel);
            this._refreshCheckBuyIntervel = null;
        }
        if(this.message){
          this.message.close()
              this.message = null
        }
    },
    async clickpersonal() {
      let that = this
      const web3 = window.web3;
     
      web3.eth.personal.sign("check_wallet", this.address).then(async(tx) => {
        that.personal = false
        localStorage.setItem('personal', true)
      })
    },
    async clickSend() {
      if(this.buyType == 'buy') {
        this.panicBuying()
      } else {
        this.batchSell()
      }
    },
    async panicBuying() {
      this.clearCheckBuyInterval();
      if (!this.tokenOutInfo || !this.tokenOutInfo.symbol) {
            this.$message.error('請輸入代币合约地址');
            return;
        }
        if(!this.status) {
          if (!this.amountIn) {
            if(this.buyType=='buy') {
             this.$message.error('請輸入买入最小金额');

            } else {
              this.$message.error('請輸入买入最小数量');

            }
              
              return;
          }
          if (!this.amountInMax) {
            if(this.buyType=='buy') {
             this.$message.error('請輸入买入最大金额');

            } else {
              this.$message.error('請輸入买入最大数量');

            }
              return;
          }
        } else {
          if(!this.amount) {
            if(this.buyType=='buy') {
             this.$message.error('請輸入固定金額');

            } else {
             this.$message.error('請輸入固定数量');

            }
              return;
          }
        }
        
        let wallets = this.wallets;
        let length = wallets.length;
        if(length == 0) {
          this.$message.error('请上傳私鑰');
            return;
        }
        let timeMargin = this.timeMargin;
        if (!timeMargin) {
            timeMargin = 1000;
        }
        this.message = this.$message({
            type: "loading",
            message: "loading",
            duration: 0,
        });
        this.clickApprove()
        if(this.instatus) {
          for(let i = 0;i < length;i++) {
            this._buy(wallets[i], i);
          }
        } else {
          this._refreshCheckBuyIntervel = setInterval(() => {
            let index = this.index;
            this._buy(wallets[index], index);
            this.index++;
            if (this.index >= length) {
                this.clearCheckBuyInterval();
            }
        }, timeMargin);
        }
        
    },
    //购买过程
    async _buy(wallet, index) {
        try {
            //当前选择的交易币种
            let selectToken = this.selectToken;
            // if (selectToken.address == this.chainConfig.WETH) {
            //     //主币余额不足
            //     // if (wallet.balance.lte(toWei(this.amountIn, selectToken.decimals))) {
            //     //     toast.show(selectToken.Symbol + '余额不足');
            //     //     return;
            //     // }
            // } else {
            //     //USDT余额不足
            //     // if (wallet.usdtBalance.lt(toWei(this.amountIn, selectToken.decimals))) {
            //     //     toast.show('USDT余额不足');
            //     //     return;
            //     // }
            //     await this.approve(wallet, this.USDTDetail.address);
            // }

            //池子代币，用指定池子
            let pairOther = this.selPoolToken.address;
            var Web3 = require("web3");
        const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));
            const swapContract = new myWeb3.eth.Contract(SwapRouter_ABI, this.swapRouter);
            let path = [];
            
            //当前代币合约信息
            let tokenOutInfo = this.tokenOutInfo;
            
           
            let amountIn = null
            
            if(this.status) {
              amountIn = toWei(this.amount, selectToken.decimals);
            } else {
              console.log("最小-最大")
              //输入
              amountIn = toWei(this.amountIn, selectToken.decimals);

              // //余额
              // let usdtBalance = wallet.usdtBalance;
              
              let amountInMax = toWei(this.amountInMax, selectToken.decimals);
              let amountDebt = amountInMax.sub(amountIn);
              if (!amountDebt.isZero()) {
                  let random = Math.random();
                  random = toWei(random + '', 18);
                  let max = new BN(MAX_INT, 10);
                  let detb = max.div(random).mod(amountDebt);
                  amountIn = amountIn.add(detb);
              }
              // if (usdtBalance.lt(amountIn)) {
              //   console.log(111111)
              //     amountIn = usdtBalance;
              // }
            }
            //路径，指定支付代币
            path.push(selectToken.address);

            //选择的代币和池子代币不一样时
            if (pairOther != selectToken.address) {
                path.push(pairOther);
            }
            path.push(tokenOutInfo.address);
            console.log(amountIn, path)
            //预估能得到多少代币
            let amountOuts = await swapContract.methods.getAmountsOut(amountIn, path).call();
            console.log("amountOuts===",amountOuts)
            let amountOut = new BN(amountOuts[amountOuts.length - 1], 10);
            //滑点
            let slige = this.slige;
            if (!slige) {
                slige = '20';
            }
            slige = parseInt(parseFloat(slige) * 100);
            //根据滑点计算得到的最小值
            let amountOutMin = amountOut.mul(new BN(10000 - slige)).div(new BN(10000));

            let gasPrice = await myWeb3.eth.getGasPrice();
            gasPrice = new BN(gasPrice, 10);
            //gas倍数
            let gasMulti = this.gasMulti;
            if (!gasMulti) {
                gasMulti = 1;
            }
            gasMulti = parseFloat(gasMulti);
            gasMulti = parseInt(gasMulti * 100);
            gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));
            console.log("data", selectToken.address, this.chainConfig.WETH);
            //Data
            let data;
            //主币购买
            if (selectToken.address == this.chainConfig.WETH) {
                data = swapContract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(
                    amountOutMin, path, wallet.address, 1914823077
                ).encodeABI();
            } else {
                data = swapContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
                    amountIn, amountOutMin, path, wallet.address, 1914823077
                ).encodeABI();
            }

            var nonce = await myWeb3.eth.getTransactionCount(wallet.address, "pending");
            console.log("nonce", nonce);

            let gas;
            //主币购买
            if (selectToken.address == this.chainConfig.WETH) {
                gas = await swapContract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(
                    amountOutMin, path, wallet.address, 1914823077
                ).estimateGas({ from: wallet.address, value: amountIn });
            } else {
                gas = await swapContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
                    amountIn, amountOutMin, path, wallet.address, 1914823077
                ).estimateGas({ from: wallet.address });
            }

            gas = new BN(gas, 10).mul(new BN(150)).div(new BN(100));

            let value = '0';
            if (selectToken.address == this.chainConfig.WETH) {
                value = amountIn;
            }
            console.log("value=====",value)
            var txParams = {
                gas: Web3.utils.toHex(gas),
                gasPrice: Web3.utils.toHex(gasPrice),
                nonce: Web3.utils.toHex(nonce),
                chainId: this.chainConfig.ChainId,
                value: Web3.utils.toHex(value),
                to: this.swapRouter,
                data: data,
                from: wallet.address,
            };
            console.log("txParams", txParams);
            //gas费
            var fee = new BN(gas, 10).mul(new BN(gasPrice, 10));
            console.log("fee", Web3.utils.fromWei(fee, "ether"));

            await this._buyTx(myWeb3, wallet, txParams, index);
        } catch (e) {
            console.log("e", e);
            wallet.loading = false
            wallet.status = false
            this.$set(this.wallets,index,wallet)
            if (index >= (this.wallets.length - 1)) {
              this.message.close()
              this.message = null
            }
        } finally {
          
        }
    },

    async _buyTx(myWeb3, wallet, txParams, index) {
        try {
            //交易签名
            let privateKey = wallet.privateKey;
            var signedTx = await myWeb3.eth.accounts.signTransaction(txParams, privateKey);
            let transaction = await myWeb3.eth.sendSignedTransaction(signedTx.rawTransaction);
            wallet.loading = false
            // 交易失败
            if (!transaction.status) {
              this.$message.error("购买失败");
              
            wallet.status = false
              this.$set(this.wallets,index,wallet)
              return
            }
            wallet.status = true
            this.$set(this.wallets,index,wallet)
            // toast.show("购买成功");
            // this.getTokenBalance(wallet, index);
        } catch (e) {
            console.log("e", e);
            wallet.loading = false
            wallet.status = false
            this.$set(this.wallets,index,wallet)
            if (index >= (this.wallets.length - 1)) {
              this.message.close()
              this.message = null
            }
        } finally {
            //购买完成删除定时器
            //this.clearCheckBuyInterval();
            if (index >= (this.wallets.length - 1)) {
              if(this.message) {
                this.message.close()
                this.message = null

              }
              this.$message("執行完畢");
            }
        }
    },
    //卖出
    async batchSell() {
        this.clearCheckBuyInterval();
        if (!this.tokenOutInfo || !this.tokenOutInfo.symbol) {
          this.$message.error('請輸入代币合约地址');
            return;
        }
        if(!this.status) {
          if (!this.sellAmountIn) {
            this.$message.error('請輸入卖出最小数量');
            return;
        }
        if (!this.sellAmountInMax) {
            this.$message.error('請輸入卖出最大数量');
            return;
        }
        } else {
          if(!this.amount) {
            this.$message.error('請輸入固定数量');
          }
        }
        
        let wallets = this.wallets;
        let length = wallets.length;
        if (length == 0) {
          this.$message.error('请上傳私鑰');
            return;
        }
        
        let timeMargin = this.timeMargin;
        if (!timeMargin) {
            timeMargin = 1000;
        }
        this.message = this.$message({
            type: "loading",
            message: "loading",
            duration: 0,
        });
        this.clickApprove()
        if(this.instatus) {
          for(let i = 0;i < length;i++) {
            this._checkSell(wallets[i], i);
          }
        } else {
          this._refreshCheckBuyIntervel = setInterval(() => {
            let index = this.index;
            this._checkSell(wallets[index],index);
            this.index++;
            if (this.index >= length) {
                this.clearCheckBuyInterval();
            }
        }, timeMargin);
        }
        
    },

    async _checkSell(wallet,index) {
        try {
          var Web3 = require("web3");
        const myWeb3 = new Web3(new Web3.providers.HttpProvider(this.rpcUrl));

            const swapContract = new myWeb3.eth.Contract(SwapRouter_ABI, this.swapRouter);

            let path = [];
            //当前选择的交易币种
            let selectToken = this.selectToken;
            //池子代币，用指定池子，否则用检测到的池子
            let pairOther = this.selPoolToken.address;
            //当前代币合约信息
            let tokenOutInfo = this.tokenOutInfo;
            //路径，当前代币
            path.push(tokenOutInfo.address);
            //池子
            if (pairOther != selectToken.address) {
                path.push(pairOther);
            }
            //指定支付代币
            path.push(selectToken.address);

            //代币余额
            let tokenBalance = wallet.tokenBalance;
            if (tokenBalance.isZero()) {
                return;
            }
            let amountIn = null
            
            if(this.status) {
              amountIn = toWei(this.amount, tokenOutInfo.decimals);
            } else {
              //输入
              amountIn = toWei(this.sellAmountIn, tokenOutInfo.decimals);
              let amountInMax = toWei(this.sellAmountInMax, tokenOutInfo.decimals);
              let amountDebt = amountInMax.sub(amountIn);
              if (!amountDebt.isZero()) {
                  let random = Math.random();
                  random = toWei(random + '', 18);
                  let max = new BN(MAX_INT, 10);
                  let detb = max.div(random).mod(amountDebt);
                  amountIn = amountIn.add(detb);
              }
              if (tokenBalance.lt(amountIn)) {
                console.log(1, tokenBalance)
                  amountIn = tokenBalance;
              }
            }
            
            //预估能得到多少代币
            let amountOuts = await swapContract.methods.getAmountsOut(amountIn, path).call();
            let amountOut = new BN(amountOuts[amountOuts.length - 1], 10);
            console.log(2)
            //滑点
            let slige = this.slige;
            if (!slige) {
                slige = '20';
            }
            slige = parseInt(parseFloat(slige) * 100);
            //根据滑点计算得到的最小值
            let amountOutMin = amountOut.mul(new BN(10000 - slige)).div(new BN(10000));

            let data;
            //卖得主币
            if (selectToken.address == this.chainConfig.WETH) {
                data = swapContract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
                    amountIn, amountOutMin, path, wallet.address, 1914823077
                ).encodeABI();
            } else {
                data = swapContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
                    amountIn, amountOutMin, path, wallet.address, 1914823077
                ).encodeABI();
            }
            var nonce = await myWeb3.eth.getTransactionCount(wallet.address, "pending");
            console.log("nonce", nonce);
            let gas;
            //卖得主币
            if (selectToken.address == this.chainConfig.WETH) {
                gas = await swapContract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
                    amountIn, amountOutMin, path, wallet.address, 1914823077
                ).estimateGas({ from: wallet.address });
            } else {
                gas = await swapContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
                    amountIn, amountOutMin, path, wallet.address, 1914823077
                ).estimateGas({ from: wallet.address });
            }

            

            let value = '0';

            //这里gasLimit直接用200万
            gas = new BN(gas, 10).mul(new BN(150)).div(new BN(100));

            let gasPrice = await myWeb3.eth.getGasPrice();
            gasPrice = new BN(gasPrice, 10);
            //gas倍数
            let gasMulti = this.gasMulti;
            if (!gasMulti) {
                gasMulti = 1;
            }
            gasMulti = parseFloat(gasMulti);
            gasMulti = parseInt(gasMulti * 100);
            gasPrice = gasPrice.mul(new BN(gasMulti)).div(new BN(100));

            var txParams = {
                gas: Web3.utils.toHex(gas),
                gasPrice: Web3.utils.toHex(gasPrice),
                nonce: Web3.utils.toHex(nonce),
                // chainId: this.chainConfig.ChainId,
                value: Web3.utils.toHex(value),
                to: this.swapRouter,
                data: data,
                from: wallet.address,
            };

            var fee = new BN(gas, 10).mul(new BN(gasPrice, 10));
            console.log("fee", Web3.utils.fromWei(fee, "ether"));

            //交易签名
            let privateKey = wallet.privateKey;
            var signedTx = await myWeb3.eth.accounts.signTransaction(txParams, privateKey);
            let transaction = await myWeb3.eth.sendSignedTransaction(signedTx.rawTransaction);
            wallet.loading = false
            // 交易失败
            if (!transaction.status) {
              this.$message.error("卖出失败");
              wallet.status = false
              this.$set(this.wallets,index,wallet)
              return
            }
            wallet.status = true
            this.$set(this.wallets,index,wallet)
            // toast.show("卖出成功");
            
            // this.getTokenBalance(wallet,index);
        } catch (e) {
            console.log("e", e);
            wallet.loading = false
            wallet.status = false
            this.$set(this.wallets,index,wallet)
            if (index >= (this.wallets.length - 1)) {
              this.message.close()
              this.message = null
            }
        } finally {
            //卖出完成
            // this.clearCheckBuyInterval();
             if (index >= (this.wallets.length - 1)) {
              if(this.message) {
                this.message.close()
                this.message = null

              }
              this.$message("執行完畢");
            }
        }
    },
    //确定要购买的代币合约
    async confirmTokenOut() {
      let tokenAddress = this.tokenOut;
      if (!tokenAddress) {
        this.$message.error("請輸入要购买的代币合约");
        return;
      }
      this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      try {
        var Web3 = require("web3");
        const myWeb3 = new Web3(
          new Web3.providers.HttpProvider(this.rpcUrl)
        );
        const commonContract = new myWeb3.eth.Contract(
          Common_ABI,
          this.chainConfig.Common
        );

        //获取要购买的代币信息
        let tokensInfo = await commonContract.methods
          .getTokenInfo(tokenAddress)
          .call();
        let symbol = tokensInfo[0];
        let decimals = tokensInfo[1];
        let totalSupply = tokensInfo[2];

        let tokenOutInfo = {
          address: tokenAddress,
          symbol: symbol,
          decimals: decimals,
          totalSupply: totalSupply,
          showTotal: showFromWei(totalSupply, decimals, 2)
        };
        // this.tokenOutInfo = tokenOutInfo;
        console.log("tokenOutInfo", tokenOutInfo);

        //获取价格
        let priceInfo = await this.getTokenPrice(tokenOutInfo);
        
       
        this.tokenOutInfo = priceInfo;
        console.log("this.tokenOutInfo",this.tokenOutInfo);
        this.batchGetTokenBalance()
      } catch (e) {
        console.log("e", e);
        this.$message.error('無效地址');
        this.tokenOutInfo = {}
      } finally {
         this.message.close()
         this.message = null
      }
    },
    //获取代币价格
    async getTokenPrice(tokenInfo) {
      
      console.log("getTokenPrice", this.chainConfig);
      var Web3 = require("web3");
      const myWeb3 = new Web3(
        new Web3.providers.HttpProvider(this.rpcUrl)
      );
      const commonContract = new myWeb3.eth.Contract(
        Common_ABI,
        this.chainConfig.Common
      );
      //获取要购买的代币价格
      let tokens = this._getTokensAddress();
      console.log("tokens", tokens)
      let tokenPriceResult = await commonContract.methods
        .getTokenPrice(
          this.swapRouter,
          tokenInfo.address,
          this.chainConfig.USDT,
          tokens
        )
        .call();
      console.log("tokenPriceResult", tokenPriceResult);
      //代币价格，需要处理USDT最小精度
      let tokenPrice = new BN(tokenPriceResult[0], 10);
      //价格的精度，本来就需要处理USDT的精度，这个精度是在USDT精度的基础上多的，还要再处理一遍
      //价格小于0.{17个0}x时，才存在这个精度
      let priceDecimals = parseInt(tokenPriceResult[1]);
      let pairOther = tokenPriceResult[2];
      let tokenReserve = tokenPriceResult[3];

      tokenInfo.tokenPrice = tokenPrice;
      tokenInfo.priceDecimals = priceDecimals;
      tokenInfo.pairOther = pairOther;
      tokenInfo.tokenReserve = tokenReserve;
      console.log("realDecimals", this.USDTDetail.decimals, priceDecimals);
      let realDecimals = this.USDTDetail.decimals + priceDecimals;
      tokenInfo.showTokenPrice = showFromWeiMore(
        tokenPrice,
        realDecimals,
        4
      );
      return tokenInfo;
    },
    //获取其他价值币的合约地址，用于调用合约
    _getTokensAddress() {
      let Tokens = this.Tokens;
      let len = Tokens.length;
      let tokensAddress = [];
      for (let i = 0; i < len; i++) {
        tokensAddress.push(Tokens[i].address);
      }
      return tokensAddress;
    },
    async clickApprove(flag) {
      
      
        this.approveNum = 0
      if (!this.wallets.length) {
        this.$message.error("请上傳私鑰");

        return;
      }
      if (!this.tokenOut) {
        this.$message.error("請輸入合约地址");
        return;
      }
        let wallets = this.wallets;
        let length = wallets.length;
        if (this.buyType == "buy") {
          let selectToken = this.selectToken;
          if (selectToken.address == this.chainConfig.WETH) {
            
          } else {
            if(flag == 'btn') {
        this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      }
            this.visibility = true
            //USDT余额不足
            for (let index = 0; index < length; index++) {
               this.approve(wallets[index],this.USDTDetail.address,index,flag);
            }
          }
        } else if (this.buyType == "sell") {
          if(flag == 'btn') {
        this.message = this.$message({
          type: 'loading',
          message: 'loading',
          duration: 0
        })
      }
          this.visibility = true
          for (let index = 0; index < length; index++) {
               this.approve(wallets[index],this.tokenOutInfo.address,index,flag);
            }
        }
        
    },
    async approve(wallet, tokenAddress,index,flag) {
      
        try {
            
            var Web3 = require("web3");
            const myWeb3 = new Web3(
              new Web3.providers.HttpProvider(this.rpcUrl)
            );
            const tokenContract = new myWeb3.eth.Contract(ERC20_ABI, tokenAddress);
            let allowance = await tokenContract.methods.allowance(wallet.address, this.swapRouter).call();
            allowance = new BN(allowance, 10);
            if (!allowance.isZero()) {
              console.log("allowance1232131231")
                return;
            }
            var gasPrice = await myWeb3.eth.getGasPrice();
            gasPrice = new BN(gasPrice, 10);

            var gas = await tokenContract.methods.approve(this.swapRouter, MAX_INT).estimateGas({ from: wallet.address });
            gas = new BN(gas, 10).mul(new BN("150", 10)).div(new BN("100", 10));

            //Data
            var data = tokenContract.methods.approve(this.swapRouter, new BN(MAX_INT, 10)).encodeABI();

            var nonce = await myWeb3.eth.getTransactionCount(wallet.address, "pending");
            console.log("nonce", nonce);

            var txParams = {
                gas: Web3.utils.toHex(gas),
                gasPrice: Web3.utils.toHex(gasPrice),
                nonce: Web3.utils.toHex(nonce),
                // chainId: this.chainConfig.ChainId,
                value: Web3.utils.toHex("0"),
                to: tokenAddress,
                data: data,
                from: wallet.address,
            };

            var fee = new BN(gas, 10).mul(new BN(gasPrice, 10));
            console.log("fee", Web3.utils.fromWei(fee, "ether"));

            //交易签名
            let privateKey = wallet.privateKey.trim();
            var signedTx = await myWeb3.eth.accounts.signTransaction(txParams, privateKey);
            let transaction = await myWeb3.eth.sendSignedTransaction(signedTx.rawTransaction);
            //交易失败
            if (!transaction.status) {
                this.$message.error("授权失败");
                return;
            }
            
        } catch (e) {
            console.log("e", e);
        } finally {
          console.log("1232131231",index)
              this.approveNum = this.approveNum + 1

          if(flag == 'btn' && index==(this.wallets.length-1)) {
            this.message.close()
            this.message = null
          }
           
        }
    }
  }
}
</script>

<style lang="less">
@import "../assets/pc/trade.less";
@import "../assets/mobile/trade.less";
</style>